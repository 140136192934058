<template>
  <div>
    <b-carousel
      id="carousel"
      :interval="5000"
      img-width="900"
      img-height="280"
      @sliding-start="onSlideStart"
      @slidind-end="onSlideEnd"
      class="mt-4"
      controls
      indicators
    >
      <b-carousel-slide img-src="@/assets/images/carousel/banner-01.jpg"></b-carousel-slide>
      <b-carousel-slide img-src="@/assets/images/carousel/banner-02.jpg"></b-carousel-slide>
      <b-carousel-slide img-src="@/assets/images/carousel/banner-03.jpg"></b-carousel-slide>
      <b-carousel-slide img-src="@/assets/images/carousel/banner-04.jpg"></b-carousel-slide>
    </b-carousel>

    <div class="inovahplast__institucional mt-4">
      <p class="lead">InovahPlast</p>

      <p class="text-muted">
        Somos uma empresa dedicada há mais de 25 anos à produção de embalagens plásticas de sopro, atendendo especialmente os setores cosmético, químico e automobilístico. Nossa missão é oferecer soluções personalizadas, sempre atentos às necessidades dos nossos clientes, para encontrar a embalagem ideal que realce e proteja seus produtos.
      </p>
      <p class="text-muted">
        Destacamos com orgulho a nossa embalagem de 1 litro Inovah Plast, especialmente projetada para atender às exigências da linha automobilística, com um design exclusivo e funcional.
      </p>
      <p class="text-muted">
        Convidamos você a conhecer mais sobre o nosso trabalho e a descobrir as vantagens de ser um cliente InovahPlast. Entre em contato conosco para obter informações detalhadas e encontrar as melhores opções para o seu negócio.
      </p>
      <p class="text-muted">
        Inove e se destaque no mercado com a InovahPlast - Sua parceira confiável em embalagens plásticas de alta qualidade!
      </p>
    </div>
    <div class="inovahplast__produtos_destaque mt-4">
      <!-- <p class="lead">Produtos em Destaque</p> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sliding: null,
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
  metaInfo:{
    title: 'Home'
  },
};
</script>

<style>
</style>